<template>
  <v-container
    fluid
    fill-height
    class="background-img"
    v-if="render"
  >
    <v-row>
      <v-col cols="6">
        <v-img
          class="logo2"
          src="../assets/cot_logo2.png"
        >
        </v-img>
      </v-col>
      <v-col class="align-self-center">
        <v-card class="elevation-12 size-card d-flex">
          <v-card-actions class="pt-2 pl-16 pr-16 d-flex justify-center align-center flex-grow-1 buttons-wrapper">
            <v-btn
              v-for="provider in providers"
              :key="provider.id"
              large
              rounded
              block
              color="primary"
              @click="redirect(provider)"
              v-ripple
              class="provider-button"
            >
              <v-icon>{{$icon('i.Login')}}</v-icon>
              <span>{{$t('t.ConnectWith', {provider: provider.name})}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import themeController from '@/themeController'
import authenticationController from '@/authenticationController'

export default {
  name: 'ExternalLoginProviderSelection',
  data: () => ({
    render: false
  }),
  computed: {
    providers: {
      get () {
        if (this.$router.currentRoute.name === 'sso-extern') {
          return authenticationController.getExternSsoProviders()
        } else {
          return authenticationController.getSsoProviders()
        }
      }
    }
  },
  created () {
    if (!authenticationController.isExternal()) {
      this.$router.replace('/login', { query: { redirect: this.$router.currentRoute.query.redirect } })
    }
    this.render = true
  },
  async mounted () {
    await themeController.loadDefaultTheme().catch(() => { })
    this.$vuetify.theme.isDark = false
    this.$vuetify.theme.themes.light.primary = '#ffffff'
    this.$vuetify.theme.themes.light.primary = themeController.primary
  },
  methods: {
    redirect (provider) {
      authenticationController.redirectExternalLoginIfPossible(provider, this.$router.currentRoute.query.redirect)
    }
  }
}
</script>

<style lang="stylus" scoped>
.provider-button
  max-height 44px
  margin-top 16px
  margin-bottom 16px

.buttons-wrapper
  flex-flow column

.background-img
  background-image url('../assets/background-login2.png')
  background-size cover

.size-card
  max-width 680px
  min-height 386px
  margin-left auto
  margin-right auto
  background-color #fff !important

.logo2
  width 100%
  max-width 600px
  max-height 600px
  margin-left auto
  margin-right auto
  z-index 2
</style>
