// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/background-login2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".provider-button[data-v-0cd91990]{max-height:44px;margin-top:16px;margin-bottom:16px}.buttons-wrapper[data-v-0cd91990]{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-flow:column;flex-flow:column}.background-img[data-v-0cd91990]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}.size-card[data-v-0cd91990]{max-width:680px;min-height:386px;background-color:#fff!important}.logo2[data-v-0cd91990],.size-card[data-v-0cd91990]{margin-left:auto;margin-right:auto}.logo2[data-v-0cd91990]{width:100%;max-width:600px;max-height:600px;z-index:2}", ""]);
// Exports
module.exports = exports;
